@import "variables";

@media (min-width: $bp-medium+1) and (max-width: $bp-large) {
  .product-list li {
    width: 275px;
  }
  .product-list li:nth-child(3n+2) {
    margin-left: 2px;
    margin-right: 2px;
  }
  .product-list li .heart {
    margin: 8px 8px 0 0;
    font-size: 11px;
  }
  .product-list li .link h3 {
    padding: 31px 16px 0;
  }
  .product-list li .link p {
    padding: 4px 16px;
    font-size: 11px;
  }
  .product-list li .link span {
    padding: 4px 16px;
    font-size: 16px;
  }
  .product-list li .link span small {
    font-size: 9px;
    margin-right: 3px;
  }
  .product-list li .link img {
    width: 100%;
  }
  .product-list.mais-vendidos li .sabores {
    width: 137px;
    height: 19px;
    font-size: 9px;
    left: 70px;
  }
  .product-list.mais-vendidos li .sabores:before {
    width: 74px;
    height: 2px;
    top: -2px;
  }
  #identificacao {
    height: 396px;
  }
  #identificacao .center-identification {
    width: 827px;
  }
  #identificacao .center-identification .left-indentification,
  #identificacao .center-identification .middle-indentification,
  #identificacao .center-identification .right-indentification {
    padding: 78px 39px 0 39px;
  }
  #identificacao .center-identification .left-indentification .title,
  #identificacao .center-identification .middle-indentification .title,
  #identificacao .center-identification .right-indentification .title {
    font-size: 16px;
    margin-bottom: 27px;
  }
  #identificacao .center-identification .left-indentification .form-group {
    padding: 0 16px;
    text-align: center;
  }
  #identificacao .center-identification .left-indentification .form-group div {
    margin: 16px 0;
  }
  #identificacao .center-identification .left-indentification .form-group div label {
    font-size: 11px;
  }
  #identificacao .center-identification .left-indentification .form-group div input {
    height: 29px;
    line-height: 28px;
    padding: 2px 8px;
    border: none;
  }
  #identificacao .center-identification .left-indentification a {
    font-size: 9px;
  }
  #identificacao .center-identification .middle-indentification {
    height: 396px;
  }
  #identificacao .center-identification .middle-indentification .text {
    margin: 0 19px;
    font-size: 11px;
  }
  #identificacao .center-identification .middle-indentification a {
    margin: 16px 23px 0 23px;
    line-height: 32px;
  }
  #identificacao .center-identification .right-indentification .text {
    margin: 0 19px 16px 19px;
    font-size: 11px;
  }
  #identificacao .center-identification .right-indentification .fb {
    margin-bottom: 8px;
  }
  #identificacao .center-identification .right-indentification .fb button {
    width: 139px;
    height: 29px;
    background-size: cover;
  }
  #identificacao .center-identification .right-indentification .ob a {
    width: 139px;
    height: 29px;
    background-size: cover;
  }
  #loja-online {
    padding: 23px 0 43px 0;
  }
  #loja-online .table-loja-online {
    width: 644px;
  }
  #loja-online .table-loja-online .table-left {
    margin-top: 23px;
  }
  #loja-online .table-loja-online .table-left .title-blue {
    font-size: 16px;
  }
  #loja-online .table-loja-online .table-left .title-blue a {
    padding: 4px 16px;
    line-height: 12px;
    font-size: 9px;
  }
  #loja-online .table-loja-online .table-left .title-blue a:before {
    width: 12px;
    bottom: 8px;
  }
  #loja-online .table-loja-online #como-comprar {
    padding-left: 47px;
    margin-top: 17px;
  }
  #loja-online .table-loja-online #como-comprar h3 {
    font-size: 23px;
  }
  #loja-online .table-loja-online #como-comprar p {
    line-height: 17px;
    font-size: 11px;
    margin-bottom: 16px;
  }
  #loja-online .table-loja-online #como-comprar .sub-title {
    margin-bottom: 4px;
  }
  #loja-online .table-loja-online #como-comprar span {
    font-size: 11px;
    line-height: 12px;
  }
  #loja-online .table-loja-online #entrega {
    padding-left: 47px;
  }
  #loja-online .table-loja-online #entrega h3 {
    font-size: 23px;
  }
  #loja-online .table-loja-online #entrega p {
    line-height: 17px;
    font-size: 11px;
    margin-bottom: 16px;
  }
  #loja-online .table-loja-online #entrega .title {
    margin-bottom: 4px;
  }
  #loja-online .table-loja-online #termo-de-uso {
    padding-left: 47px;
  }
  #loja-online .table-loja-online #termo-de-uso h3 {
    font-size: 23px;
  }
  #loja-online .table-loja-online #termo-de-uso p {
    line-height: 17px;
    font-size: 11px;
    margin-bottom: 16px;
  }
  .indicators-left {
    margin-left: 3px;
  }
  #menu-sobre {
    height: 45px;
    top: 63px;
  }
  #menu-sobre li {
    height: 45px;
    line-height: 45px;
  }
  #menu-sobre li a {
    height: 45px;
    line-height: 45px;
    padding: 0 12px;
    font-size: 9px;
  }
  #truffes {
    margin-bottom: 54px;
  }
  #truffes .conheca {
    margin-top: -43px;
  }
  #truffes .conheca h2 {
    margin-top: 15px;
  }
  #truffes .conheca h2 span:before {
    left: 334px;
  }
  #truffes .conheca h2 span:after {
    left: -85px;
  }
  #truffes .customize p span:before {
    left: 334px;
  }
  #truffes .customize p span:after {
    left: -85px;
  }
  #trufes-carousel-details.show {
    max-height: 396px;
  }
  #trufes-carousel-details .close {
    width: 16px;
    height: 16px;
    top: 19px;
    right: 39px;
  }
  #trufes-carousel-details .close:before {
    width: 22px;
    height: 22px;
    top: -11px;
    left: -11px;
  }
  #trufes-carousel-details .close:after {
    width: 22px;
    height: 22px;
    top: 4px;
    left: -10px;
  }
  #trufes-carousel-bottom ul li a .img {
    width: 483px;
  }
  #trufes-carousel-bottom ul li a .img img {
    width: 483px;
  }
  #trufes-carousel-bottom ul li a .text {
    width: 349px;
  }
  #trufes-carousel-bottom ul li a .text h3 {
    margin-left: -139px;
    font-size: 23px;
  }
  #trufes-carousel-bottom ul li a .text h3:after {
    top: 11px;
  }
  #trufes-carousel-bottom ul li a .text .img-small {
    padding: 8px 16px 8px;
  }
  #trufes-carousel-bottom ul li a .text .img-small img {
    width: 109px;
    height: 122px;
  }
  #trufes-carousel-bottom ul li a .text .description {
    padding: 8px 16px 8px;
  }
  .text-value-box span {
    display: block;
    float: none;
  }
  #best-seller-carousel {
    margin-bottom: 50px;
  }
  .nav-tabs {
    padding: 0 8px;
  }
  .nav-tabs li a {
    height: 31px;
    line-height: 33px;
    font-size: 11px;
  }
  .modal .modal-dialog {
    width: 419px;
  }
  .modal .modal-dialog.big {
    width: 636px;
  }
  .modal .modal-content .modal-header {
    height: 31px;
    line-height: 34px;
    font-size: 14px;
  }
  .modal .modal-content .modal-header .close {
    width: 16px;
    height: 16px;
    top: 9px;
    right: 8px;
  }
  .modal .modal-content .modal-header .close:before {
    width: 22px;
    height: 22px;
    top: -11px;
    left: -11px;
  }
  .modal .modal-content .modal-header .close:after {
    width: 22px;
    height: 22px;
    top: 4px;
    left: -10px;
  }
  .container.small {
    width: 835px;
  }
  #img-header .img {
    height: 385px;
  }
  #img-header .img.small {
    height: 199px;
  }
  #img-header .img .text-header {
    height: 225px;
    padding: 39px;
  }
  #img-header .img .text-header p {
    font-size: 47px;
    line-height: 47px;
    padding: 0 39px;
  }
  #blocos {
    margin-top: -159px;
    max-height: 380px;
  }
  #blocos .left-blocos {
    padding: 30px 47px 30px 47px;
    height: 380px;
    max-height: 380px;
  }
  #blocos .left-blocos.purple-left {
    background-size: cover;
  }
  #blocos .left-blocos.sabores-left {
    background-size: cover;
  }
  #blocos .left-blocos.sabores-left h3 {
    width: 295px;
  }
  #blocos .left-blocos.sabores-left a {
    margin-top: 54px;
  }
  #blocos .left-blocos h3 {
    width: 272px;
    line-height: 28px;
  }
  #blocos .left-blocos p {
    font-size: 14px;
    line-height: 19px;
  }
  #blocos .left-blocos ul li {
    font-size: 10px;
  }
  #blocos .left-blocos ul.list {
    margin: 31px 0 0 19px;
  }
  #blocos .left-blocos ul.list li {
    padding: 4px 16px;
  }
  #blocos .left-blocos a {
    width: 280px;
    height: auto;
    margin: 20px 0 0 0px;
  }
  #blocos .left-blocos a span {
    width: 10px;
    height: 15px;
    margin: -8px 4px 0 0;
    background-size: cover;
  }
  #blocos .left-blocos a .colheita {
    width: 124px;
    font-size: 14px;
    padding: 0 0 0 16px;
  }
  .right-blocos {
    height: 380px;
    max-height: 380px;
  }
  .right-blocos img {
    width: 419px;
    height: 380px;
  }
  .right-blocos .text {
    padding: 47px;
  }
  .right-blocos .text p {
    font-size: 14px;
    margin-top: 16px;
  }
  .right-blocos.sabores-right .text p {
    margin-top: 78px;
  }
  .right-blocos .text h3 {
    font-size: 23px;
  }
  .right-blocos .text a.link {
    font-size: 11px;
    margin-top: 43px;
  }
  .right-blocos .text a.btn {
    margin-top: 23px;
  }
  .border-dual-color {
    border-top-width: 4px;
    border-bottom-width: 4px;
    height: 8px;
  }
  #voltar {
    width: 163px;
    margin-top: 62px;
    font-size: 9px;
  }
  #voltar:before {
    width: 31px;
    height: 26px;
    margin-left: -12px;
    background-size: cover;
  }
  .conheca-chocolates {
    bottom: 23px;
    margin-left: -46px;
  }
  .central-title {
    margin-top: 31px;
    margin-bottom: 31px;
  }
  .central-title h2 span {
    line-height: 28px;
  }
  .central-title h2 span:after {
    width: 61px;
    top: 12px;
    left: -93px;
  }
  .central-title h2 span:before {
    width: 61px;
    top: 12px;
    right: -93px;
  }
  .central-title .description {
    font-size: 12px;
    padding: 0 39px;
  }
  #mais-vendidos .sell-more span,
  #refinar-busca .sell-more span {
    margin-left: 8px;
  }
  #mais-vendidos .mais-vendidos-menu,
  #refinar-busca .mais-vendidos-menu {
    min-width: 124px;
  }
  #mais-vendidos .mais-vendidos-menu li,
  #refinar-busca .mais-vendidos-menu li {
    margin: 2px;
  }
  #mais-vendidos .mais-vendidos-menu li a,
  #refinar-busca .mais-vendidos-menu li a {
    font-size: 9px;
  }
  #refinar-busca .sell-more .btn {
    padding: 0 16px;
  }
  #refinar-busca .sell-more .btn span {
    font-size: 12px;
    margin-right: 5px;
  }
  #refinar-busca .refinar-busca-menu {
    width: 248px;
  }
  #refinar-busca .refinar-busca-menu .price {
    margin-bottom: 39px !important;
  }
  #refinar-busca .refinar-busca-menu .price b {
    font-size: 11px;
  }
  #refinar-busca .refinar-busca-menu .price p {
    margin-bottom: 19px !important;
  }
  #refinar-busca .refinar-busca-menu .price a {
    margin-top: -19px;
  }
  #refinar-busca .refinar-busca-menu .price .slider {
    width: 124px;
    margin: 0 4px;
  }
  #refinar-busca .refinar-busca-menu .produtos,
  #refinar-busca .refinar-busca-menu .sabores,
  #refinar-busca .refinar-busca-menu .price {
    margin: 0 16px;
  }
  #refinar-busca .refinar-busca-menu .produtos p,
  #refinar-busca .refinar-busca-menu .sabores p,
  #refinar-busca .refinar-busca-menu .price p {
    margin-top: 4px;
    font-size: 11px;
  }
  #refinar-busca .refinar-busca-menu .produtos ul li,
  #refinar-busca .refinar-busca-menu .sabores ul li,
  #refinar-busca .refinar-busca-menu .price ul li {
    font-size: 9px;
    padding: 4px;
    margin-top: 2px;
  }
  .less {
    width: 10px;
    height: 10px;
    margin-left: -10px;
  }
  .less:after {
    width: 5px;
    top: 5px;
    left: 2px;
  }
  #produtos-topo {
    height: 355px;
  }
}